<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i> {{$t('navbar.operation')}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('navbar.strategyDelay')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">

      <div>
        <el-select clearable class="filter-item" style="width: 200px" v-model="queryData.eventId" placeholder="事件">
          <el-option v-for="(value, key) in eventMap" :key="key" :label="value" :value="key">
          </el-option>
        </el-select>
        <el-select clearable class="filter-item" style="width: 200px" v-model="queryData.strategyId" placeholder="策略">
          <el-option v-for="(value, key) in strategyMap" :key="key" :label="value" :value="key">
          </el-option>
        </el-select>
        <el-select clearable class="filter-item" style="width: 200px" v-model="queryData.status" placeholder="状态">
          <el-option v-for="(item, index) in delayStatusArr" :key="index" :label="item" :value="index">
          </el-option>
        </el-select>
        <el-radio-group v-model="queryData.queryTimeTag">
          <el-radio label="create">创建时间</el-radio>
          <el-radio label="exec">执行时间</el-radio>
        </el-radio-group>
        <date-input param="queryData"></date-input>
        <el-button class="filter-item" style="margin-left:10px !important" type="primary" icon="el-icon-search" @click="handleList">{{$t('table.query')}}</el-button>
      </div>

      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>

        <el-table-column align="center" label="用户ID">
          <template slot-scope="scope">
            <span>{{scope.row.userId}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="事件">
          <template slot-scope="scope">
            <span>{{eventMap[scope.row.eventId]}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="策略">
          <template slot-scope="scope">
            <span>{{strategyMap[scope.row.strategyId]}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span>{{scope.row.status | delayStatusFmt}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="预计执行时间">
          <template slot-scope="scope">
            <span>{{scope.row.execTime}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>

      </el-table>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]"
          :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { strategyDelay, strategyConfigAll, messageEventAll } from "@/api/request";

const delayStatusArr = ['待处理', '处理成功', '处理失败', '事件排斥取消', '业务取消', '超时取消']
export default {
  name: "StrategyDelay",
  data() {
    return {
      listLoading: false,
      queryData: { name: undefined, status: undefined, queryTimeTag: undefined, page: 1, rows: 10 },
      statusOptions: [{ key: 1, value: 'status1' }, { key: 0, value: 'status0' }],
      list: [],
      strategyMap: {},
      eventMap: {},
      total: 1,
      initNum: 0
    };
  },
  filters: {
    delayStatusFmt(v) {
      return delayStatusArr[v];
    }
  },
  computed: {
    delayStatusArr() {
      return delayStatusArr;
    }
  },
  created() {
    strategyConfigAll().then(res => {
      res.data.forEach(e => {
        this.strategyMap[e.id] = e.strategyName;
      });
      this.init();
    });
    messageEventAll().then(res => {
      res.data.forEach(e => {
        this.eventMap[e.id] = e.eventName;
      });
      this.init();
    });
  },
  methods: {
    init() {
      this.initNum++;
      if (this.initNum >= 2) {
        this.handleList();
      }
    },
    handleList() {
      this.listLoading = true;
      strategyDelay("get", this.queryData)
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
        })
        .catch(_ => (this.listLoading = false));
    },
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    }
  }
};
</script>
