<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i>{{ $t("navbar.operation") }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("navbar.strategyConfig")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="flex-justify-between boxmargin">
        <div>API产品通知短信</div>
        <el-button class="filter-item" style="margin-left: 10px" type="primary" icon="el-icon-edit" @click="Adddata()">{{
          $t("table.add") }}</el-button>
      </div>

      <compTable :tablelistarr="list" :tableHeader="tableHeader" @EditMethod='EditMethod' @EditStatus='EditStatus'
        :editdisplay="true" :statusDisplay="true"></compTable>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryData.page" :page-sizes="[10, 20, 30, 50]" :page-size="queryData.row"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :title="formData.id ? '编辑' : '添加'" :visible.sync="dialogFormVisible"
      :before-close="closeDialog" class="Editbox Editdiabox">
      <el-form ref="formData" :rules="dialogFormVisiblerules" :model="formData" label-width="135px" style='width:100%'>
        <el-form-item label="策略名称" prop="strategyName">
          <el-input v-model="formData.strategyName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('table.event')" prop="eventId">
          <el-select v-model="formData.eventId" style='width: 280px;'>
            <el-option v-for="item in eventList" :key="item.id" :label="item.eventName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="马甲包选择" prop="mjbName">
          <el-select v-model="formData.mjbName" filterable>
            <el-option v-for="(item, index) in mjbNameFcm" :key="index"
              :label="item.fcmProjectId ? `${item.mjbName} - [${item.fcmProjectId}]` : item.mjbName"
              :value="item.mjbName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="SMS 用户" prop="smsWeight">
          <el-input v-model="formData.smsWeight" :oninput="numInput"></el-input>
        </el-form-item>
        <el-form-item label="PUSH 用户" prop="pushWeight">
          <el-input v-model="formData.pushWeight" :oninput="numInput"></el-input>
        </el-form-item>
        <el-form-item label="SMS+PUSH 用户" prop="allWeight">
          <el-input v-model="formData.allWeight" :oninput="numInput"></el-input>
        </el-form-item>
        <div class="channelItem">内容配置</div>
        <el-form-item label="短信" label-width="70px" prop='smsContentType'>
          <el-radio-group v-model="formData.smsContentType" @input="getSMSType(formData.smsContentType, 'SMS')">
            <el-radio label="SINGLE">单一文案</el-radio>
            <el-radio label="MULTIPLE">多条文案配置</el-radio>
          </el-radio-group>
        </el-form-item>


        <div class="operate" v-if='formData.smsContentType == "MULTIPLE"' @click.stop="addItem('SMS')">添加文案</div>
        <el-table :data="formData.SMSConfigList" ref="singleTable" style="width: 100%;" center border
          highlight-current-row class="tb-edit" v-if="formData.smsContentType" @current-change="clickRow">
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column label="内容" align="center">
            <template #default="{ row, $index }">
              <el-form-item :prop="`SMSConfigList.${$index}.content`" :rules="dialogFormVisiblerules.content"
                label-width="0px">
                <span v-show="row.isEdit">
                  <el-input type="textarea" :rows="4" v-model="row.content" :onkeydown="regExEnglish"
                    :onkeyup="regExEnglish" @input.native="descInput(row.content, $event)"></el-input>
                  <span class="grey right" v-if="descInput(row.content) >= 0">{{ descInput(row.content) }}/160</span>
                  <span class="red right" v-else>超出字符数{{ descInput(row.content) }}</span>
                </span>
                <span v-show="!row.isEdit">{{ row.content }}</span>
              </el-form-item>

            </template>
          </el-table-column>
          <el-table-column label="比例" align="center" width="110px" v-if='formData.smsContentType == "MULTIPLE"'>
            <template #default="{ row, $index }">
              <el-form-item label-width="0px" :prop="`SMSConfigList.${$index}.weight`"
                :rules="dialogFormVisiblerules.weight">
                <el-input v-model="row.weight" placeholder="请输入" :oninput="numInput" v-show="row.isEdit" />
                <span v-show="!row.isEdit"> {{ row.weight }}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="60px">
            <template #default="{ row, $index }">
              <el-button type="danger" circle @click.stop="SMSDelete(row, $index)" icon="el-icon-delete"> </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="PUSH" label-width="70px" prop='pushContentType'>
          <el-radio-group v-model="formData.pushContentType" @input="getSMSType(formData.pushContentType, 'PUSH')">
            <el-radio label="SINGLE">单一文案</el-radio>
            <el-radio label="MULTIPLE">多条文案配置</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="operate" v-if='formData.pushContentType == "MULTIPLE"' @click="addItem('PUSH')">添加文案</div>
        <div>
          <el-table :data="formData.PUSHConfigList" ref="table" style="width: 100%;" center @current-change="clickPUSHRow"
            border class="tb-edit" highlight-current-row v-if="formData.pushContentType">
            <el-table-column label="序号" align="center" type="index" />
            <el-table-column label="标题" align="center" width="160px">
              <template #default="{ row, $index }">
                <el-form-item label-width="0px" :prop="`PUSHConfigList.${$index}.title`"
                  :rules="dialogFormVisiblerules.title">
                  <el-input v-model="row.title" placeholder="请输入" v-show="row.isEdit" />
                  <span v-show="!row.isEdit">{{ row.title }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="内容" align="center">
              <template #default="{ row, $index }">
                <el-form-item :prop="`PUSHConfigList.${$index}.content`" :rules="dialogFormVisiblerules.content"
                  label-width="0px">
                  <span v-show="row.isEdit">
                    <el-input type="textarea" :rows="4" v-model="row.content" :onkeydown="regExEnglish"
                      :onkeyup="regExEnglish" @input.native="descInput(row.content, $event)"></el-input>
                    <span class="grey right" v-if="descInput(row.content) >= 0">{{ descInput(row.content) }}/160</span>
                    <span class="red right" v-else>超出字符数{{ descInput(row.content) }}</span>
                  </span>
                  <span v-show="!row.isEdit">{{ row.content }}</span>
                </el-form-item>
                <span></span>
              </template>
            </el-table-column>
            <el-table-column label=" 图片上传" align="center" width="140px">
              <template #default="{ row, $index }">
                <ImgUpload @uploadImgData="uploadImgData" :idx='$index' :remoteImage="row.imgUrl" :action='action'
                  v-if="row.isEdit" />
                <span v-else> <img :src="row.imgUrl" alt="" width="50px"></span>
              </template>
            </el-table-column>
            <el-table-column label="比例" align="center" width="110px" v-if='formData.pushContentType == "MULTIPLE"'>
              <template #default="{ row, $index }">
                <el-form-item label-width="0px" :prop="`PUSHConfigList.${$index}.weight`"
                  :rules="dialogFormVisiblerules.weight">
                  <el-input v-model="row.weight" placeholder="请输入" :oninput="numInput" v-show="row.isEdit" />
                  <span v-show="!row.isEdit">{{ row.weight }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="60px">
              <template #default="{ row, $index }">
                <el-button type="danger" circle @click.stop="PUSHDelete(row, $index)" icon="el-icon-delete"> </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t('table.cancel') }}</el-button>
        <el-button @click="saveChanges" type="primadangerry">{{ $t('table.confirm') }}</el-button>
      </div>
    </el-dialog>



  </div>
</template>
<script>
import API from "@/api";
import compTable from '@/components/table/index'
import { PatchStrategyConfig } from "@/api/request";

export default {
  components: {
    compTable
  },
  data() {
    var validatePUSH = (rule, value, callback) => {
      if (this.formData.pushWeight > 0 || this.formData.allWeight > 0) {
        if (value) callback();
        callback(new Error('请配置PUSH内容'));
      } else {
        callback();
      }
    }
    var validateSMS = (rule, value, callback) => {
      if (this.formData.smsWeight > 0 || this.formData.allWeight > 0) {
        if (value) callback();
        callback(new Error('请配置短信内容'));
      } else {
        callback();
      }
    };
    var validateContent = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写内容'));
      } else if (this.descInput(value) < 0) {
        callback(new Error('内容超出字符数'))
      } else {
        callback();
      }
    }
    return {
      addEditstatus: true, //true 为新增状态  false编辑
      dialogFormVisible: false,
      listLoading: false,
      dialogupdate: false,
      queryData: { page: 1, rows: 10 },  //分页
      total: 1,  //表格总条数
      tableHeader: [], //表头信息
      list: [],  //表格数据
      eventList: [], //事件列表
      mjbNameFcm: [],//马甲包Name
      uploadData: {
        action: `mapi/common/upload4WhiteList`,
        accept: '.xlsx,.xls',
        path: ''
      },
      action: 'mapi/common/uploadPic',
      //添加编辑
      formData: {
        strategyName: undefined,
        eventId: undefined,
        messageType: undefined,
        messageContent: "",
      },

      dialogFormVisiblerules: {
        strategyName: [
          { required: true, message: '请填写' + this.$t('table.strategyName'), trigger: 'blur' }
        ],
        eventId: [
          { required: true, message: '请选择' + this.$t('table.event'), trigger: 'change' }
        ],
        messageType: [
          { required: true, message: '请选择' + this.$t('table.message_type'), trigger: 'change' }
        ],
        messageContent: [
          { required: true, message: '请填写' + this.$t('table.sendContent'), trigger: 'blur' }
        ],
        content: [{ required: true, validator: validateContent, trigger: "blur" },],
        smsWeight: [{ required: true, message: "请填写SMS比例", trigger: "blur" }],
        pushWeight: [{ required: true, message: "请填写PUSH比例", trigger: "blur" }],
        allWeight: [{ required: true, message: "请填写PUSH+SMS比例", trigger: "blur" }],
        mjbName: [{ required: true, message: "请选择马甲包", trigger: 'change' }],
        smsContentType: [{ validator: validateSMS, trigger: 'change' }],
        pushContentType: [{ validator: validatePUSH, trigger: 'change' }],
        weight: [{ required: true, message: "请输入", trigger: "blur" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      remnant: 160, //备注最大输入250个字符
      wildcardlist: {
        "${creditline}": 5,
        "${allcreditline}": 5,
        "${lastdays}": 2,
        "${deadlinetime}": 2,
        "${newbalance}": 8,
        "${lastamount}": 8,
        "${amount}": 8,
        "${loantime}": 10,
        "${repaytime}": 10,
        "${arrivaltime}": 10,
        "${waittime}": 2,
        "${productname}": 20,
        "${applink}": 25,
        "${loanappname}": 20,
        "${loginappname}": 20,
        "${lastname}": 20,
        "${loginapplink}": 25,
      },
      regExEnglish: "this.value=this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,'')",
      numInput: "value=value.replace(/[^0-9.]/g,'')"
    };
  },
  created() {

    this.getMessageEvent()  //事件列表请求
    this.getStrategyConfig(); //表格列表请求
    this.addtableHeader()   //添加表头信息
    this.getmMjbNameFCM()//马甲包列表

  },
  mounted() {
    this.eventTransformation()
  },
  methods: {
    Adddata() {
      this.dialogFormVisible = true;
      this.addEditstatus = true
      this.restFormData()
      this.descInput()
    },

    //马甲包下拉框渲染
    getmMjbNameFCM() {
      API.mjbNameFCM().then(res => {
        if (res.data.code != 200) { this.$message.error(res.data.code.msg); return }
        this.mjbNameFcm = res.data.data
      })
    },
    //编辑
    EditMethod(row) {
      this.dialogFormVisible = true;
      this.addEditstatus = false
      this.formData = Object.assign({}, row);
      const msgDetailList = row.msgDetailList
      this.formData.SMSConfigList = msgDetailList.filter(item => item.type === 'SMS')
      this.formData.PUSHConfigList = msgDetailList.filter(item => item.type === 'PUSH')
      // this.descInput()
    },
    //保存修改
    saveChanges() {
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          let listform = {}
          this.formData['strategyType'] = 'INSTANT'
          if (this.formData.allWeight > 0 ) {
            if( this.formData.SMSConfigList.length <= 0 || this.formData.PUSHConfigList.length <= 0){
              this.$message.error("请至少配置一项SMS或者PUSH内容");
              return
            }
          }
          if (this.formData.smsWeight > 0 && this.formData.SMSConfigList.length <= 0) {
            this.$message.error("请至少配置一项SMSH内容");
            return
          }
          if (this.formData.pushWeight > 0 && this.formData.PUSHConfigList.length <= 0) {
            this.$message.error("请至少配置一项PUSH内容");
            return
          }
          const msgDetailListStr = JSON.stringify([...this.formData.PUSHConfigList, ...this.formData.SMSConfigList])
          delete this.formData.status
          const params = { ...this.formData, msgDetailListStr }
          this.addEditstatus == true ? this.PostStrategyConfig(params) : this.PatchStrategyConfigs(params)
          // this.$refs['formData'].resetFields();
        } else {
          return false;
        }
      });

    },
    //激活未激活
    EditStatus(row) {
      const parmas = {
        id: row.id,
        status: row.status == 1 ? 0 : 1
      }
      this.$confirm("是否更改当前状态？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = await API.updateStatus(parmas)
        if (data.data.code != '200') { this.$message.error(data.data.msg); return }
        this.getStrategyConfig()
      }).catch(() => { });
    },

    closeDialog() {
      this.dialogFormVisible = false;
      this.$refs['formData'].resetFields();
      this.restFormData()
    },


    addtableHeader() {
      return this.tableHeader = [
        {
          label: this.$t('table.createTime'),
          prop: 'createTime',
          formatter: (row, column) => { return this.utils.utlis_filtiema(row.createTime); },
          id: 1
        },
        {
          label: this.$t('table.strategyName'),
          prop: 'strategyName',
          minwidth: "150px",
          id: 2
        },
        {
          label: this.$t('table.event'),
          prop: 'eventId',
          id: 3,
          formatter: (row, column) => {
            return this.eventTransformation(row.eventId);
          },
        },
        {
          label: '马甲包名称',
          prop: 'mjbName',
          id: 5,
        },
        {
          label: this.$t('table.status'),
          prop: 'status',
          id: 4,
          formatter: (row, column) => {
            if (row.status == 0) {
              return "未启用";
            } else if (row.status == 1) {
              return "启用";
            }

          },
        },
      ]
    },
    //事件
    eventTransformation(v) {
      let data
      if (this.eventList.length > 0) {
        this.eventList.forEach((e, index) => {
          if (e.id == v) {
            return data = e.eventName;
          }
        });
      }

      return data
    },

    //通配符计算
    descInput(value) {
      let wildcardNum = 0; //通配符自定义占位
      let wildcardwords = 0; //通配符实际占位
      let wildcardNo = 0; //去除通配 与 空格 符剩余占位
      let Space = 0; //空格占位符
      let wildcardText = value || '';
      const textwildcard = value || '';
      Object.keys(this.wildcardlist).forEach((key) => {
        if (textwildcard.indexOf(key) != -1) {
          wildcardNum += this.wildcardlist[key] * ((textwildcard.split(key)).length - 1)
          wildcardwords += key.length * ((textwildcard.split(key)).length - 1)
          wildcardText = wildcardText.replaceAll(key, "")
        }
        wildcardNo = wildcardText.replaceAll(" ", "").length;
        Space = (textwildcard.split(" ").length - 1) * 2;
      });
      return 160 - wildcardNo - wildcardNum - Space;
    },

    //策略配置列表
    async getStrategyConfig() {
      const { data: data, code: code } = await API.getStrategyConfig({ ...this.queryData, strategyType: "INSTANT" });
      if (data.data != null) {
        this.list = data.data.map(value => {
          if (value.status == 1) {
          }
          value.status = value.status == 1 ? true : false
          return value;
        });
        this.total = data.total;
      } else {
      }
    },
    //更新编辑
    async PatchStrategyConfigs(list) {
      const data = await API.PatchStrategyConfig(list);
      if (data.data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.data.msg + ' ' + this.$t("tips.success"),
          type: 'success',
          duration: 2000
        });
        this.dialogFormVisible = false;
        this.getStrategyConfig()
      } else if (data.data.code != 200) {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.data.msg + ' ' + this.$t("tips.error"),
          duration: 2000
        });
      }

    },
    async getMessageEvent() {
      const data = await API.getMessageEvent();
      this.eventList = data.data.data
    },
    async PatchDeveloperParam(list) {
      const data = await API.PatchDeveloperParam(list);
      if (data.data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.data.msg + ' ' + this.$t("tips.success"),
          type: 'success',
          duration: 2000
        });
        this.dialogFormVisible = false;
        this.getStrategyConfig()
      } else if (data.data.code != 200) {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.data.msg + ' ' + this.$t("tips.error"),
          duration: 2000
        });
      }
    },
    async PostStrategyConfig(list) {
      const { data: data, code: code } = await API.PostStrategyConfig(list);
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + ' ' + this.$t("tips.success"),
          type: 'success',
          duration: 2000
        });
        this.dialogFormVisible = false;
        this.getStrategyConfig()
      } else if (data.code != 200) {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + ' ' + this.$t("tips.error"),
          duration: 2000
        });
      }

    },

    //分页
    handleSizeChange(v) {
      this.queryData.row = v;
      this.getStrategyConfig();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.getStrategyConfig();
    },
    /* 重置表单 */
    restFormData() {
      this.formData = {
        strategyName: undefined,
        eventId: undefined,
        smsWeight: "",
        pushWeight: '',
        allWeight: '',
        smsContentType: '',
        pushContentType: '',
        uploadFilePath: '',
        mjbName: '',
        SMSConfigList: [{ type: 'SMS', title: '', content: '', imgUrl: '', weight: '', isEdit: false }],
        PUSHConfigList: [{ type: 'PUSH', title: '', content: '', imgUrl: '', weight: '', isEdit: false }],
      }
    },
    restformupdateData() {
      this.formupdateData = {
        id: '',
        dataRelationConfig: undefined,
      }
    },

    /* 文案项添加 */
    addItem(type) {
      if (type == 'SMS') {
        this.formData.SMSConfigList.push({ type: 'SMS', title: '', content: '', imgUrl: '', weight: '', isEdit: false });
        return
      }
      this.formData.PUSHConfigList.push({ type: 'PUSH', title: '', content: '', imgUrl: '', weight: '', isEdit: false });
    },

    /* 短信配置类型 */
    getSMSType(val, type) {
      console.log(val, type);
      if (val == 'SINGLE' && type == "SMS" && this.formData.SMSConfigList.length > 0) {
        this.formData.SMSConfigList = [this.formData.SMSConfigList[0]]
        return
      }
      if (this.formData.PUSHConfigList.length > 0) this.formData.PUSHConfigList = [this.formData.PUSHConfigList[0]]
    },
    /* 图片上传成功数据 */
    uploadImgData(data, index) {
      this.formData.PUSHConfigList[index].imgUrl = data.fileUrl
    },

    /* 文件上传参数 */
    uploadFileData(file) {
      this.formData.uploadFilePath = file
    },

    /* SMS选中行 */
    clickRow(currentRow, oldCurrentRow) {
      console.log(currentRow, oldCurrentRow);
      if (oldCurrentRow) oldCurrentRow.isEdit = false
      currentRow.isEdit = true
    },
    /* PUSH选中行 */
    clickPUSHRow(currentRow, oldCurrentRow) {
      console.log(currentRow, oldCurrentRow);
      if (oldCurrentRow) oldCurrentRow.isEdit = false
      currentRow.isEdit = true
    },
    /* SMS配置删除 */
    SMSDelete(row, index) {
      if (this.formData.SMSConfigList <= 1) {
        // this.$message.error("");
      }
      this.formData.SMSConfigList.splice(index, 1)
    },
    PUSHDelete(row, index) {
      this.formData.PUSHConfigList.splice(index, 1)
    },
  },

  watch: {

  },
};
</script>

<style lang="less" scoped>
.boxmargin {
  margin: 10px 0;
}

.channelItem {
  font-weight: bold;
}

.channelItem::before {
  content: "*";
  color: red;
  margin-right: 2px;
  margin-left: 5px;
}

.operate {
  text-align: right;
  color: rgb(22, 165, 242);
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 2px;
}

.grey {
  color: #999;
}

.right {
  display: block;
  text-align: right;
}

.red {
  color: #ff4949
}
</style>