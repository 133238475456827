<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i> {{$t('navbar.operation')}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('navbar.messageRecord')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">

      <div>
        <el-select clearable class="filter-item" style="width: 200px" v-model="queryData.strategyId" placeholder="策略">
          <el-option v-for="(value, key) in strategyMap" :key="key" :label="value" :value="key">
          </el-option>
        </el-select>
        <el-select clearable class="filter-item" style="width: 200px" v-model="queryData.sendStatus" placeholder="发送状态">
          <el-option v-for="(item, index) in sendStatusArr" :key="index" :label="item" :value="index">
          </el-option>
        </el-select>
        <date-input param="queryData"></date-input>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList">{{$t('table.query')}}</el-button>
      </div>

      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>

        <el-table-column align="center" label="用户ID">
          <template slot-scope="scope">
            <span>{{scope.row.userId}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="策略">
          <template slot-scope="scope">
            <span>{{strategyMap[scope.row.strategyId]}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="消息内容">
          <template slot-scope="scope">
            <span>{{scope.row.messageContent}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="消息发送类型">
          <template slot-scope="scope">
            <span>{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发送状态">
          <template slot-scope="scope">
            <span>{{scope.row.sendStatus | sendStatusFmt}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发送时间">
          <template slot-scope="scope">
            <span>{{scope.row.sendTime}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注">
          <template slot-scope="scope">
            <span>{{scope.row.remark}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>

      </el-table>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]"
          :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { messageRecord, strategyConfigAll } from "@/api/request";

const sendStatusArr = ['待定', '待发送', '已发送', '发送失败', '发送限制取消', '发送超时', '数据异常', '已过期']
export default {
  name: "MessageRecord",
  data() {
    return {
      listLoading: false,
      queryData: { name: undefined, status: undefined, page: 1, rows: 10 },
      statusOptions: [{ key: 1, value: 'status1' }, { key: 0, value: 'status0' }],
      list: [],
      strategyMap: {},
      total: 1
    };
  },
  filters: {
    sendStatusFmt(v) {
      return sendStatusArr[v];
    }
  },
  computed: {
    sendStatusArr() {
      return sendStatusArr;
    }
  },
  created() {
    strategyConfigAll().then(res => {
      res.data.forEach(e => {
        this.strategyMap[e.id] = e.strategyName;
      });
      this.handleList();
    });
  },
  methods: {
    handleList() {
      this.listLoading = true;
      messageRecord("get", this.queryData)
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
        })
        .catch(_ => (this.listLoading = false));
    },
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    }
  }
};
</script>
