<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> <i class="el-icon-date"></i> {{ $t("navbar.operation") }} </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("navbar.channelAmountConfirmMode") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-input style="width: 160px" clearable class="filter-item" placeholder="请输入渠道ID" v-model="queryData.channelId" />
        <el-select clearable class="filter-item" style="width: 180px" v-model="queryData.amountConfirmMode" placeholder="额度确认模式">
          <el-option label="AmtAdd-提额确认" value="AmtAdd"> </el-option>
          <el-option label="AmtCfm-额度确认" value="AmtCfm"> </el-option>
        </el-select>
        <el-select clearable class="filter-item" style="width: 160px" v-model="queryData.enabled" placeholder="是否启用">
          <el-option label="启用" value="1"> </el-option>
          <el-option label="禁用" value="0"> </el-option>
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList">{{ $t("table.query") }}</el-button>

        <el-button class="filter-item" style="float: right; margin-right: 0px !important" @click="handleCreate" type="primary" icon="el-icon-edit">
          {{ $t("table.add") }}
        </el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" label="主键ID" prop="id" />
        <el-table-column align="center" label="渠道ID" prop="channelId" />
        <el-table-column align="center" label="额度确认模式" prop="amountConfirmMode" />
        <el-table-column align="center" label="是否启用">
          <template slot-scope="{ row }">
            <el-switch v-model="row.enabled" @change="updateStatus(row)" :active-value="1" :inactive-value="0"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="创建人" prop="createBy" />
        <el-table-column align="center" label="更新时间" prop="updateTime" />
        <el-table-column align="center" label="更新人" prop="updateBy" />
        <el-table-column align="center" label="备注" prop="remark" />
        <el-table-column align="center" :label="$t('table.operate')" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{ $t("table.edit") }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryData.rows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog :close-on-click-modal="false" :title="formData.id ? '编辑' : '添加'" :visible.sync="dialogFormVisible" width="680px">
        <el-form ref="dataForm" :model="formData" label-position="right" :rules="rules" label-width="120px">
          <el-form-item label="渠道ID" prop="channelId" v-if="!formData.id">
            <el-input v-model="formData.channelId"></el-input>
          </el-form-item>
          <el-form-item label="额度确认模式" prop="amountConfirmMode">
            <el-radio-group v-model="formData.amountConfirmMode">
              <el-radio label="AmtAdd">AmtAdd-提额确认</el-radio>
              <el-radio label="AmtCfm">AmtCfm-额度确认</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="6" v-model="formData.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="formData.id" type="primary" @click="updateData">{{ $t("table.confirm") }}</el-button>
          <el-button v-else type="primary" @click="createData">{{ $t("table.confirm") }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { channelAmountConfirmMode } from "@/api/request";
export default {
  name: "channelAmountConfirmMode",
  data() {
    return {
      dialogFormVisible: false,
      listLoading: false,
      queryData: { channelId: undefined, channelId: undefined, enabled: undefined, page: 1, rows: 10 },
      formData: {},
      isCreate: false,
      list: [],
      total: 1,
      rules: {
        channelId: [{ required: true, message: "请输入渠道ID" }],
        amountConfirmMode: [{ required: true, message: "请选择额度确认模式" }],
      },
    };
  },
  mounted() {
    this.handleList();
  },
  methods: {
    /* 渠道额度确认模式配置列表 */
    handleList() {
      this.listLoading = true;
      channelAmountConfirmMode("get", this.queryData)
        .then((res) => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
        })
        .catch((_) => (this.listLoading = false));
    },
    /* 重置表单 */
    restFormData() {
      this.formData = {
        id: undefined,
        channelId: undefined,
        remark: undefined,
        amountConfirmMode: undefined,
        enabled: 1,
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    /* 添加操作 */
    handleCreate() {
      this.isCreate = true;
      this.restFormData();
      this.dialogFormVisible = true;
    },
    /* 编辑操作 */
    handleUpdate(row) {
      const { id, remark, amountConfirmMode } = row;
      this.formData = { id, remark, amountConfirmMode };
      this.dialogFormVisible = true;
    },
    /* 分页 */
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    /* 分页 */
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    /* 配置修改 */
    updateData() {
      this.submitData("patch", this.$t("tips.update"));
    },
    /* 添加配置 */
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    submitData(method, msg, param, i) {
      this.submitDataBefore(method != "delete", (_) => {
        channelAmountConfirmMode(method, param || this.formData).then((res) => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + " " + this.$t("tips.success"),
            type: "success",
            duration: 2000,
          });
          this.queryData.page = 1;
          this.handleList();
          this.dialogFormVisible = false;
        });
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate((_) => _ && cb()) : cb();
    },
    /* 状态更新 */
    updateStatus(row) {
      console.log(row.enabled);
      let text = row.enabled === 1 ? "启用" : "停用";
      this.$confirm('确认要"' + text + '"该渠道吗?')
        .then(function() {
          return channelAmountConfirmMode("patch", { id: row.id, enabled: row.enabled });
        })
        .then(() => {
          this.queryData.page = 1;
          this.handleList();
          this.$notify({
            title: this.$t("tips.success"),
            message: "更新成功",
            type: "success",
            duration: 2000,
          });
        })
        .catch(function(error) {
          row.enabled = row.enabled === "N" ? "Y" : "N";
        });
    },
  },
};
</script>
