<template>
  <div class="dialogdback">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i>{{ $t("navbar.operation") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("navbar.eventManagement")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="flex-justify-between boxmargin">
        <div>事件管理</div>
        <!-- <el-button
          class="filter-item"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-edit"
          @click="Adddata()"
          >{{ $t("table.add") }}</el-button
        > -->
      </div>
      <compTable :tablelistarr="list" :tableHeader="tableHeader" @ConfigDisplay="ConfigDisplay" :configDisplay="true"
        :indexdisplay="true"></compTable>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryData.page" :page-sizes="[10, 20, 30, 50]" :page-size="queryData.row"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :title="$t('navbar.strategyConfig')" :visible.sync="dialogFormVisible"
      :before-close="dialogDialog">
      <div class="flex-justify-between boxmargin">
        <div>事件名：{{ addEventName }}</div>
        <el-button class="filter-item" style="margin-left: 10px" type="primary" icon="el-icon-edit"
          @click="AddConfig()">{{ $t("table.add") }}</el-button>
      </div>
      <compTable :tablelistarr="eventtablelist" :tableHeader="eventtableHeader" @EditOFFMethod="EditOFFMethod"
        @deleteMethod="deleteMethod" @EditONMethod="EditONMethod" @EditMethod="EditMethod" :editdisplay="isEdit"
        :deleteDisplay="true" :onoffDisplay="true" :indexdisplay="true"></compTable>
    </el-dialog>
    <div @click="cancelHighlight($event)">
      <el-dialog :close-on-click-modal="false" :title="Configform.id ? '编辑' : '添加'" :visible.sync="dialogConfig"
        :before-close="closeDialog" class="Editbox Editdiabox">
        <div class="flex-justify-between boxmargin">
          <el-form ref="Configform" :rules="rulesConfig" :model="Configform" label-width="135px">
            <el-form-item label="策略名称" prop="strategyName">
              <el-input v-model="Configform.strategyName"></el-input>
            </el-form-item>
            <el-form-item label="文件上传" v-if="CurrentStrategy.typeName === 'WHITE_LIST'" prop="uploadFilePath">
              <FileUpload ref="child" :uploadData="uploadData" @uploadFileData="uploadFileData"
                :paramsData="Configform" />
              <el-input v-show="false" v-model="Configform.uploadFilePath"></el-input>
            </el-form-item>
            <el-form-item label="周期" prop="timeOffset" v-if="CurrentStrategy.typeName !== 'WHITE_LIST'">
              <el-select v-model="Configform.timeOffset">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="马甲包选择" prop="mjbName">
              <el-select v-model="Configform.mjbName" filterable>
                <el-option v-for="(item, index) in mjbNameFcm" :key="index"
                  :label="item.fcmProjectId ? `${item.mjbName} - [${item.fcmProjectId}]` : item.mjbName"
                  :value="item.mjbName">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="SMS 用户" prop="smsWeight">
              <el-input v-model="Configform.smsWeight" @input="plusInput('smsWeight')"></el-input>
            </el-form-item>
            <el-form-item label="PUSH 用户" prop="pushWeight">
              <el-input v-model="Configform.pushWeight" @input="plusInput('pushWeight')"></el-input>
            </el-form-item>
            <el-form-item label="SMS+PUSH 用户" prop="allWeight">
              <el-input v-model="Configform.allWeight" @input="plusInput('allWeight')"></el-input>
            </el-form-item>
            <div class="channelItem">内容配置</div>
            <el-form-item label="短信" label-width="70px" prop='smsContentType'>
              <el-radio-group v-model="Configform.smsContentType" @input="getSMSType(Configform.smsContentType, 'SMS')">
                <el-radio label="SINGLE">单一文案</el-radio>
                <el-radio label="MULTIPLE">多条文案配置</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="operate" v-if='Configform.smsContentType == "MULTIPLE"' @click.stop="addItem('SMS')">添加文案</div>
            <el-table :data="Configform.SMSConfigList" ref="singleTable" style="width: 100%;" center
              @selection-change="(selections) => { handleSelectionChange(selections) }" border highlight-current-row
              class="tb-edit" @current-change="clickRow" v-if="Configform.smsContentType">
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column label="内容" align="center">
                <template #default="{ row, $index }">
                  <el-form-item :prop="`SMSConfigList.${$index}.content`" :rules="rulesConfig.content" label-width="0px">
                    <span v-show="row.isEdit">
                      <el-input type="textarea" :rows="4" v-model="row.content" :onkeydown="regExEnglish"
                        :onkeyup="regExEnglish" @input.native="descInput(row.content, $event)"></el-input>
                      <span class="grey right" v-if="descInput(row.content) >= 0">{{ descInput(row.content) }}/160</span>
                      <span class="red right" v-else>超出字符数{{ descInput(row.content) }}</span>
                    </span>
                    <span v-show="!row.isEdit">{{ row.content }}</span>
                  </el-form-item>
                  <!-- <span>{{row.content}}</span> -->
                </template>
              </el-table-column>
              <el-table-column label="比例" align="center" width="110px" v-if='Configform.smsContentType == "MULTIPLE"'>
                <template #default="{ row, $index }">
                  <el-form-item label-width="0px" :prop="`SMSConfigList.${$index}.weight`" :rules="rulesConfig.weight">
                    <el-input v-model="row.weight" placeholder="请输入" :oninput="numInput" v-show="row.isEdit" />
                    <span v-show="!row.isEdit"> {{ row.weight }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="60px">
                <template #default="{ row, $index }">
                  <el-button type="danger" circle @click.stop="SMSDelete(row, $index)" icon="el-icon-delete"> </el-button>
                </template>
              </el-table-column>
            </el-table>


            <el-form-item label="PUSH" label-width="70px" prop='pushContentType'>
              <el-radio-group v-model="Configform.pushContentType"
                @input="getSMSType(Configform.pushContentType, 'PUSH')">
                <el-radio label="SINGLE">单一文案</el-radio>
                <el-radio label="MULTIPLE">多条文案配置</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="operate" v-if='Configform.pushContentType == "MULTIPLE"' @click="addItem('PUSH')">添加文案</div>
            <div>
              <el-table :data="Configform.PUSHConfigList" ref="table" style="width: 100%;" center border class="tb-edit"
                highlight-current-row v-if="Configform.pushContentType" @current-change="clickRow">
                <el-table-column label="序号" align="center" type="index" />
                <el-table-column label="标题" align="center" width="160px">
                  <template #default="{ row, $index }">
                    <el-form-item label-width="0px" :prop="`PUSHConfigList.${$index}.title`" :rules="rulesConfig.title">
                      <el-input v-model="row.title" placeholder="请输入" v-show="row.isEdit" />
                      <span v-show="!row.isEdit">{{ row.title }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="内容" align="center">
                  <template #default="{ row, $index }">
                    <el-form-item :prop="`PUSHConfigList.${$index}.content`" :rules="rulesConfig.content"
                      label-width="0px">
                      <span v-show="row.isEdit">
                        <el-input type="textarea" :rows="4" v-model="row.content" :onkeydown="regExEnglish"
                          :onkeyup="regExEnglish" @input.native="descInput(row.content, $event)"></el-input>
                        <span class="grey right" v-if="descInput(row.content) >= 0">{{ descInput(row.content)
                        }}/160</span>
                        <span class="red right" v-else>超出字符数{{ descInput(row.content) }}</span>
                      </span>
                      <span v-show="!row.isEdit">{{ row.content }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label=" 图片上传" align="center" width="140px">
                  <template #default="{ row, $index }">
                    <ImgUpload @uploadImgData="uploadImgData" :idx='$index' :remoteImage="row.imgUrl" :action="action"
                      v-if="row.isEdit" />
                    <span v-else> <img :src="row.imgUrl" alt="" width="50px"></span>
                  </template>
                </el-table-column>
                <el-table-column label="比例" align="center" width="110px" v-if='Configform.pushContentType == "MULTIPLE"'>
                  <template #default="{ row, $index }">
                    <el-form-item label-width="0px" :prop="`PUSHConfigList.${$index}.weight`" :rules="rulesConfig.weight">
                      <el-input v-model="row.weight" placeholder="请输入" :oninput="numInput" v-show="row.isEdit" />
                      <span v-show="!row.isEdit">{{ row.weight }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="60px">
                  <template #default="{ row, $index }">
                    <el-button type="danger" circle @click.stop="PUSHDelete(row, $index)" icon="el-icon-delete">
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <el-form-item label="内容" prop="messageContent">
            <el-input type="textarea" :rows="6" v-model="Configform.messageContent" :onkeydown="this.regExEnglish"
              :onkeyup="this.regExEnglish" @input="descInput"></el-input>
            <span style="float: right; color: #999" v-if="this.remnant >= 0">{{ this.remnant }}/160</span>
            <span style="float: right; color: #ff4949" v-else>超出字符数{{ -this.remnant }}</span>
          </el-form-item> -->
            <!-- 
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="Configform.status">
              <el-radio :label="1">ON</el-radio>
              <el-radio :label="0">OFF</el-radio>
            </el-radio-group>
          </el-form-item> -->
            <el-form-item align="center">
              <el-button type="primary" @click="servaclose">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>

  </div>
</template>
<script>
import API from "@/api";
import compTable from "@/components/table/index";
import { PatchStrategyConfig } from "@/api/request";

export default {
  components: {
    compTable,
  },
  data() {
    var validatePUSH = (rule, value, callback) => {
      if (this.Configform.pushWeight > 0 || this.Configform.allWeight > 0) {
        if (value) callback();
        callback(new Error('请配置PUSH内容'));
      } else {
        callback();
      }
    }
    var validateSMS = (rule, value, callback) => {
      if (this.Configform.smsWeight > 0 || this.Configform.allWeight > 0) {
        if (value) callback();
        callback(new Error('请配置短信内容'));
      } else {
        callback();
      }
    };
    var validateContent = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写内容'));
      } else if (this.descInput(value) < 0) {
        callback(new Error('内容超出字符数'))
      } else {
        callback();
      }
    }
    return {
      addEditstatus: true, //true 为新增状态  false编辑
      dialogFormVisible: false,
      dialogConfig: false, //新增策略弹出
      queryData: { page: 1, rows: 10 }, //分页
      total: 1, //表格总条数
      tableHeader: [], //表头信息
      eventtableHeader: [], //事件表头表头信息
      list: [], //表格数据
      eventtablelist: [], //事件表格数据
      eventList: [], //事件列表
      addEventName: "", //当前选择事件名
      CurrentlistEvents: false, //flse 为列表页    ture为事件列表页
      remnant: 160, //备注最大输入250个字符
      addEventId: '', //当前选择事件ID
      refUpload: null,
      mjbNameFcm: [],//马甲包Name
      //添加策略配置 form
      Configform: {},
      action: 'mapi/common/uploadPic',
      //策略方案列表
      options: [],
      groupIdarr: [],
      uploadData: {
        action: `mapi/common/upload4WhiteList`,
        accept: '.xlsx,.xls',
        path: ''
      },
      isEdit: true,
      rulesConfig: {
        strategyName: [{ required: true, message: "请输入策略名称", trigger: "change" }],
        uploadFilePath: [{ required: true, message: "请上传白名单文件", trigger: "change" }],
        timeOffset: [{ required: true, message: "请选择周期", trigger: "change" },],
        mjbName: [{ required: true, message: "请选择马甲包", trigger: "change" }],
        content: [{ required: true, validator: validateContent, trigger: "blur" },],
        smsWeight: [{ required: true, message: "请填写SMS比例", trigger: "blur" }],
        pushWeight: [{ required: true, message: "请填写PUSH比例", trigger: "blur" }],
        allWeight: [{ required: true, message: "请填写PUSH+SMS比例", trigger: "blur" }],
        smsContentType: [{ validator: validateSMS, trigger: 'change' }],
        pushContentType: [{ validator: validatePUSH, trigger: 'change' }],
        weight: [{ required: true, message: "请输入", trigger: "blur" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      CurrentStrategy: {},//当前选择策略
      wildcardlist: {
        "${creditline}": 5,
        "${allcreditline}": 5,
        "${lastdays}": 2,
        "${deadlinetime}": 2,
        "${newbalance}": 8,
        "${lastamount}": 8,
        "${amount}": 8,
        "${loantime}": 10,
        "${repaytime}": 10,
        "${arrivaltime}": 10,
        "${waittime}": 2,
        "${productname}": 20,
        "${applink}": 25,
        "${loanappname}": 20,
        "${loginappname}": 20,
        "${lastname}": 20,
        "${loginapplink}": 25,
      },
      count: null,
      regExEnglish: "value=value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,'')",
      numInput: "value=value.replace(/[^0-9.]/g,'')"
    };
  },
  created() {
    this.getMessageEvent(); //事件列表请求
    // this.getStrategyConfig(); //表格列表请求
    this.addtableHeader(); //添加表头信息
    this.addtableHeaderConfig(); //添加表头信息
    this.GETstrategyGroup() //策略组id
    this.getmMjbNameFCM()//马甲包列表
  },
  mounted() { },
  methods: {
    //事件管理=>策列配置操作
    async ConfigDisplay(row) {
      this.addEventName = this.eventTransformation(row.id);
      this.RenderDropDown(row)
      this.addEventId = row.id
      this.StrategyConfigeven(row.id);
      this.CurrentStrategy.eventId = row.id
      this.CurrentStrategy.strategyType = 'PERIOD'
      this.CurrentStrategy.typeName = row.typeName
      this.dialogFormVisible = true;
      this.isEdit = row.typeName === 'WHITE_LIST' ? false : true
    },

    AddConfig() {
      this.CurrentlistEvents = false;
      this.dialogConfig = true;
      this.defaultrestConfigform() //新增 设置默认值
      this.descInput();
    },
    //off方法
    EditOFFMethod(row) {
      this.$confirm("您确定要停用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let listarr = Object.assign({}, row);
          listarr.status = 0;
          this.PatchStrategyConfigs(listarr, 'status');
        })
        .catch(() => { });
    },
    //on方法
    EditONMethod(row) {
      this.$confirm("您确定要启用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let listarr = Object.assign({}, row);
          listarr.status = 1;
          this.PatchStrategyConfigs(listarr, 'status');
        })
        .catch(() => { });
    },
    //删除策略方案方法
    deleteMethod(row) {
      this.$confirm("您确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.DELETEStrategyConfig(row)
        })
        .catch(() => { });

    },
    //保存(增改)策略配置
    servaclose() {
      const msgDetailListStr = JSON.stringify([...this.Configform.PUSHConfigList, ...this.Configform.SMSConfigList])
      this.Configform = { ...this.Configform, ...this.CurrentStrategy, msgDetailListStr }
      console.log(this.Configform);
      this.$refs["Configform"].validate((valid) => {
        if (valid) {
          if (this.Configform.allWeight > 0) {
            if (this.Configform.SMSConfigList.length <= 0 || this.Configform.PUSHConfigList.length <= 0) {
              this.$message.error("请至少配置一项SMS或者PUSH内容");
              return
            }
          }
          if (this.Configform.smsWeight > 0 && this.Configform.SMSConfigList.length <= 0) {
            this.$message.error("请至少配置一项SMSH内容");
            return
          }
          if (this.Configform.pushWeight > 0 && this.Configform.PUSHConfigList.length <= 0) {
            this.$message.error("请至少配置一项PUSH内容");
            return
          }
          this.CurrentlistEvents == false ? this.PostStrategyConfig(this.Configform) : this.PatchStrategyConfigs(this.Configform);
        } else {
          return false;
        }
      });
    },
    /* 策列配置编辑Dialog */
    EditMethod(row) {
      this.CurrentlistEvents = true;
      this.dialogConfig = true;
      this.Configform = Object.assign({}, row);
      const msgDetailList = row.msgDetailList
      this.Configform.SMSConfigList = msgDetailList.filter(item => item.type === 'SMS')
      this.Configform.PUSHConfigList = msgDetailList.filter(item => item.type === 'PUSH')
      console.log(this.Configform);
      delete this.Configform.msgDetailList
    },

    /* 事件管理列表表头配置 */
    addtableHeader() {
      return (this.tableHeader = [
        {
          label: this.$t("table.event"),
          prop: "eventName",
          id: 3,
        },
      ]);
    },
    /* 策略配置列表表头配置 */
    addtableHeaderConfig() {
      return (this.eventtableHeader = [
        {
          label: "策略名称",
          prop: "strategyName",
          minwidth: "300px",
          id: 2,
        },
        {
          label: this.$t("table.message_type"),
          prop: "messageType",
          id: 2,
          formatter: (row, column) => {
            return `SMS:${row.smsWeight};\n PUSH: ${row.pushWeight};\n PUSH+SMS: ${row.allWeight}`;
          },
        },
        {
          label: '马甲包名称',
          prop: 'mjbName',
          id: 5,
        },
        {
          label: this.$t("table.status"),
          prop: "status",
          id: 4,
          formatter: (row, column) => {
            if (row.status == 0) {
              return "未启用";
            } else if (row.status == 1) {
              return "启用";
            }
          },
        },
      ]);
    },
    //不同事件渲染下拉框
    RenderDropDown(row) {
      let listarr = row.timeOptions.split('/');
      this.options = listarr.map(value => {
        let Symbol = ((value / 1440) > 0) ? "+" : ''
        let day = value < 1400 && value > 0 ? (value / 60) + "h" : (value / 1440) == 0 ? "" : (value / 1440) + "d"
        return { value: Number(value), label: "T" + Symbol + day };
      });
    },
    //事件
    eventTransformation(v) {
      let data;
      this.eventList.forEach((e, index) => {
        if (e.id == v) {
          return (data = e.eventName);
        }
      });
      return data;
    },
    //渲染策略方案
    optionsformation(v) {
      let data;
      this.options.forEach((e, index) => {
        if (e.value == v) {
          return (data = e.label);
        }
      });
      return data;
    },

    dialogDialog() {
      this.dialogFormVisible = false;

    },
    /* 关闭Diallog */
    closeDialog() {
      this.dialogConfig = false;
      this.$refs['Configform'].resetFields();
      this.defaultrestConfigform()
    },

    //通配符计算
    descInput(value, e) {
      let wildcardNum = 0; //通配符自定义占位
      let wildcardwords = 0; //通配符实际占位
      let wildcardNo = 0; //去除通配 与 空格 符剩余占位
      let Space = 0; //空格占位符
      let wildcardText = value || '';
      const textwildcard = value || '';
      Object.keys(this.wildcardlist).forEach((key) => {
        if (textwildcard.indexOf(key) != -1) {
          wildcardNum += this.wildcardlist[key] * ((textwildcard.split(key)).length - 1)
          wildcardwords += key.length * ((textwildcard.split(key)).length - 1)
          wildcardText = wildcardText.replaceAll(key, "")
        }
        wildcardNo = wildcardText.replaceAll(" ", "").length;
        Space = (textwildcard.split(" ").length - 1) * 2;
      });
      this.remnant = 160 - wildcardNo - wildcardNum - Space;
      return 160 - wildcardNo - wildcardNum - Space;

    },


    //请求（事件管废弃）
    async getStrategyConfig() {
      const { data: data, code: code } = await API.getStrategyConfig({
        ...this.queryData,
        strategyType: "PERIOD",
      });
      if (data.data != null) {
        this.list = data.data;
        this.total = data.total;
      } else {
      }
    },
    //策略组ID下拉框渲染
    async GETstrategyGroup() {
      const { data: data, code: code } = await API.GETstrategyGroup();
      if (data.data != null) {
        this.groupIdarr = data.data
      } else {
      }
    },
    //马甲包下拉框渲染
    getmMjbNameFCM() {
      API.mjbNameFCM().then(res => {
        if (res.data.code != 200) { this.$message.error(res.data.code.msg); return }
        this.mjbNameFcm = res.data.data
      })
    },
    /* 策略配置列表 */
    async StrategyConfigeven(eventId) {
      const { data: data, code: code } = await API.getStrategyConfig({
        eventId: eventId,
        strategyType: "PERIOD",
      });
      if (data.data != null) {
        this.eventtablelist = data.data;
      } else {
      }
    },
    //更新编辑
    async PatchStrategyConfigs(list, flag = '') {
      console.log(list, '44444');
      const { data: data, code: code } = await API.PatchStrategyConfig(list);
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + " " + this.$t("tips.success"),
          type: "success",
          duration: 2000,
        });
        this.StrategyConfigeven(list.eventId);
        this.closeDialog()
      } else if (data.code != 200) {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + " " + this.$t("tips.error"),
          duration: 2000,
        });
      }

    },
    /* 删除策略配置 */
    async DELETEStrategyConfig(list) {
      const { data: data, code: code } = await API.DELETEStrategyConfig({ id: list.id });
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + ' ' + this.$t("tips.success"),
          type: 'success',
          duration: 2000
        });
      } else {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + ' ' + this.$t("tips.error"),
          duration: 2000
        });
      }
      this.StrategyConfigeven(list.eventId)
    },
    /* 新增策略配置 */
    async PostStrategyConfig(list) {
      // 白名单接口处理
      const { data: data, code: code } = this.CurrentStrategy.typeName === 'WHITE_LIST' ? await API.whiteListSave({ ...list }) : await API.PostStrategyConfig({ ...list });
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + " " + this.$t("tips.success"),
          type: "success",
          duration: 2000,
        });
        this.closeDialog()
        this.StrategyConfigeven(this.addEventId);
      } else {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + " " + this.$t("tips.error"),
          duration: 2000,
        });
      }

    },


    /* 事件管理列表 */
    async getMessageEvent() {
      const data = await API.getPERIODEvent({ ...this.queryData, eventType: 2 });
      this.list = data.data.data;
      this.total = data.data.total;
      this.eventList = data.data.data;
    },

    //分页
    handleSizeChange(v) {
      this.queryData.row = v;
      this.getMessageEvent();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.getMessageEvent();
    },
    restConfigform() {
      this.Configform = {
        messageType: undefined,
        timeOffset: undefined,
        groupId: undefined,
        messageContent: '',
        status: "",
      };
    },
    /* 重置表单 */
    defaultrestConfigform() {
      this.Configform = {
        strategyName: '',
        mjbName: '',
        smsWeight: "",
        pushWeight: '',
        allWeight: '',
        smsContentType: '',
        pushContentType: '',
        timeOffset: '',
        uploadFilePath: '',
        SMSConfigList: [{ type: 'SMS', title: '', content: '', imgUrl: '', weight: '', isEdit: false }],
        PUSHConfigList: [{ type: 'PUSH', title: '', content: '', imgUrl: '', weight: '', isEdit: false }],
      };
      this.$refs.child && this.$refs.child.$refs.upload.clearFiles()

    },
    /* 空白取消编辑模式 */
    cancelHighlight(event) {
      // if (this.$refs.singleTable && !this.$refs.singleTable.$el.contains(event.target)) {
      //   this.$refs.singleTable && this.$refs.singleTable.setCurrentRow();
      // }
    },
    /* INPUT正整数限制 */
    plusInput(key) {
      this.Configform[key] = this.Configform[key].replace(/[^0-9.]/g, '')
    },
    /* 文案项添加 */
    addItem(type) {
      if (type == 'SMS') {
        this.Configform.SMSConfigList.push({ type: 'SMS', title: '', content: '', imgUrl: '', weight: '', isEdit: false });
        return
      }
      this.Configform.PUSHConfigList.push({ type: 'PUSH', title: '', content: '', imgUrl: '', weight: '', isEdit: false });
    },

    /* 选中行 */
    clickRow(currentRow, oldCurrentRow) {
      if (oldCurrentRow) oldCurrentRow.isEdit = false
      currentRow.isEdit = true
    },

    /* 短信配置类型 */
    getSMSType(val, type) {
      if (val == 'SINGLE' && type == "SMS" && this.Configform.SMSConfigList.length > 0) {
        this.Configform.SMSConfigList = [this.Configform.SMSConfigList[0]]
        return
      }
      if(this.Configform.PUSHConfigList.length > 0)this.Configform.PUSHConfigList = [this.Configform.PUSHConfigList[0]]

    },
    /* 短信配置保存校验 */
    SMSSave(index) {
      const prop = [`inConfigList.${index}.weight`, `inConfigList.${index}.messageContent`]
      this.$refs["Configform"].validateField(prop, (valid) => {
        console.log(valid);
        if (valid) {
          alert('失败')
        }
      });
    },
    /* 图片上传成功数据 */
    uploadImgData(data, index) {
      this.Configform.PUSHConfigList[index].imgUrl = data.fileUrl
    },

    /* 文件上传参数 */
    uploadFileData(file) {
      this.Configform.uploadFilePath = file
    },
    /* SMS配置删除 */
    SMSDelete(row, index) {
      this.Configform.SMSConfigList.splice(index, 1)
    },
    /* PUSH配置删除 */
    PUSHDelete(row, index) {
      this.Configform.PUSHConfigList.splice(index, 1)
    },

    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrent(row, oldrow) {
      console.log(row);
      if (row) this.descInput(row.content);
      // this.$refs.singleTable.setCurrentRow(oldrow);
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.boxmargin {
  margin: 10px 0;
}

.dialogdback /deep/ .el-dialog {
  width: 70%;
  min-width: 650px;
}

.Editbox /deep/ .el-dialog {
  width: 55%;
  min-width: 68%;
}

.Editdiabox /deep/ .el-dialog__body {
  color: #606266;
}

.Editbox /deep/ form {
  width: 100%;
}

.Editbox /deep/ .el-select {
  width: 100%;
}

.channelItem {
  font-weight: bold;
}

.channelItem::before {
  content: "*";
  color: red;
  margin-right: 2px;
  margin-left: 5px;
}

.operate {
  text-align: right;
  color: rgb(22, 165, 242);
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 2px;
}

.grey {
  color: #999;
}

.right {
  display: block;
  text-align: right;
}

.red {
  color: #ff4949
}
</style>
<style>
/* .tb-edit .el-form-item {
    display: none
}
.tb-edit .current-row .el-form-item {
    display: block
}
.tb-edit .current-row .el-form-item+span {
    display: none
} */


/* .tb-edit .el-textarea {
    display: none
}
.tb-edit .current-row .el-textarea {
    display: block
}
.tb-edit .current-row .el-textarea+span {
    display: none
} */
</style>