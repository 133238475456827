<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> <i class="el-icon-date"></i> {{ $t("navbar.operation") }} </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("navbar.productSpecialSplitterConfig") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-button class="filter-item" style="float: right; margin-right: 0px !important" @click="handleCreate" type="primary" icon="el-icon-edit">
          {{ $t("table.add") }}
        </el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" label="终端业务线" prop="terminalId">
          <template slot-scope="{ row }">
            {{ row.terminalId | terminalIdFilter(terminalList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="设备类型">
          <template slot-scope="{ row }">
            {{ row.deviceType == 1 ? "Android" : "iOS" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品1">
          <template slot-scope="{ row }">
            {{ row.productId1 | productIdFilter(productAllList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品2">
          <template slot-scope="{ row }">
            {{ row.productId2 | productIdFilter(productAllList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品3">
          <template slot-scope="{ row }">
            {{ row.productId3 | productIdFilter(productAllList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品1结清次数" prop="productId1SettleCount" />
        <el-table-column align="center" label="产品2结清次数" prop="productId2SettleCount" />
        <el-table-column align="center" label="流程模式">
          <template slot-scope="{ row }">
            <span v-if="row.processMode == 1">常规</span>
            <span v-if="row.processMode == 2">合规</span>
            <span v-if="row.processMode == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="创建人" prop="createBy" />
        <el-table-column align="center" label="更新时间" prop="createTime" />
        <el-table-column align="center" label="更新人" prop="updateBy" />
        <el-table-column align="center" :label="$t('table.operate')" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{ $t("table.edit") }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryData.rows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog :close-on-click-modal="false" :title="formData.id ? '编辑' : '添加'" :visible.sync="dialogFormVisible" width="680px">
        <el-form ref="dataForm" :model="formData" label-position="right" :rules="rules" label-width="150px">
          <el-form-item label="终端业务线" prop="terminalId">
            <el-select class="filter-item" @change="fillQueryOptions" v-model="formData.terminalId" filterable placeholder="终端业务线" :disabled="!!formData.id">
              <el-option v-for="item in terminalList" :key="item.id" :label="`${item.id}-${item.terminalName}`" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-radio-group v-model="formData.deviceType" :disabled="!!formData.id">
              <el-radio :label="1">Android</el-radio>
              <el-radio :label="2">iOS</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品1" prop="productId1">
            <el-select class="filter-item" v-model="formData.productId1" filterable placeholder="产品1">
              <el-option v-for="item in productList" :key="item.id" :label="`${item.id}-${item.productName}`" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品2" prop="productId2">
            <el-select class="filter-item" v-model="formData.productId2" filterable placeholder="产品2">
              <el-option v-for="item in productList" :key="item.id" :label="`${item.id}-${item.productName}`" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品3" prop="productId3">
            <el-select class="filter-item" v-model="formData.productId3" filterable placeholder="产品3">
              <el-option v-for="item in productList" :key="item.id" :label="`${item.id}-${item.productName}`" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品1结清次数配置" prop="productId1SettleCount">
            <el-input v-model="formData.productId1SettleCount" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></el-input>
          </el-form-item>
          <el-form-item label="产品2结清次数配置" prop="productId2SettleCount">
            <el-input v-model="formData.productId2SettleCount" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></el-input>
          </el-form-item>
          <el-form-item label="流程模式" prop="processMode">
            <el-radio-group v-model="formData.processMode">
              <el-radio :label="1">常规</el-radio>
              <el-radio :label="2">合规</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="formData.id" type="primary" @click="updateData">{{ $t("table.confirm") }}</el-button>
          <el-button v-else type="primary" @click="createData">{{ $t("table.confirm") }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { splitterStageRuleRelSafeOptions } from "@/api/request";
import API from "@/api";
export default {
  name: "productSpecialSplitterConfig",
  data() {
    return {
      dialogFormVisible: false,
      listLoading: false,
      queryData: { page: 1, rows: 10 },
      formData: {},
      isCreate: false,
      list: [],
      total: null,
      rules: {
        terminalId: [{ required: true, message: "请选择终端业务线" }],
        deviceType: [{ required: true, message: "请选择设备类型" }],
        productId1: [{ required: true, message: "请选择产品1" }],
        productId2: [{ required: true, message: "请选择产品2" }],
        productId3: [{ required: true, message: "请选择产品3" }],
        productId1SettleCount: [{ required: true, message: "请输入产品1结清次数配置" }],
        productId2SettleCount: [{ required: true, message: "请输入产品2结清次数配置" }],
      },
      terminalList: [],
      productList: [],
      productAllList: [],
    };
  },
  async mounted() {
    await this.getAllProductList();
    await this.handleTerminalList();
    await this.handleList();
  },
  filters: {
    terminalIdFilter(val, terminalList) {
      if (!val || !terminalList) {
        return val || "";
      }
      const currentVal = terminalList.find((item) => item.id == val);
      return `${currentVal.id}-${currentVal.terminalName}`;
    },
    productIdFilter(val, productList) {
      if (!val || !productList) {
        return val || "";
      }
      let currentVal = productList && productList.find((item) => item.id == val);
      return `${currentVal && currentVal.id}-${currentVal && currentVal.productName}`;
    },
  },
  methods: {
    /* 渠道额度确认模式配置列表 */
    handleList() {
      this.listLoading = true;
      API.GetProductConfig("get", this.queryData)
        .then((res) => {
          console.log(res);
          this.list = res.data.data;
          this.total = res.data.total;
          this.listLoading = false;
        })
        .catch((_) => (this.listLoading = false));
    },
    /* 查询渠道列表 */
    handleTerminalList() {
      splitterStageRuleRelSafeOptions().then((res) => {
        this.terminalList = res.data.filter((item) => item.id !== "1001");
      });
    },
    /* 获取产品列表 */
    handleProductList(id) {
      API.GetProductList(id).then((res) => {
        this.productList = res.data.data;
      });
    },
    /* 终端业务线更新 */
    fillQueryOptions(val) {
      this.handleProductList(val);
      this.formData.productId1 = undefined;
      this.formData.productId2 = undefined;
      this.formData.productId3 = undefined;
    },

    /* 重置表单 */
    restFormData() {
      this.formData = {
        id: undefined,
        terminalId: undefined,
        deviceType: undefined,
        productId1: undefined,
        productId2: undefined,
        productId3: undefined,
        productId1SettleCount: undefined,
        productId2SettleCount: undefined,
        processMode: 1,
      };
      this.productList = [];
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    /* 添加操作 */
    handleCreate() {
      this.isCreate = true;
      this.restFormData();
      this.dialogFormVisible = true;
    },
    /* 编辑操作 */
    handleUpdate(row) {
      this.formData = row;
      this.handleProductList(this.formData.terminalId);
      this.dialogFormVisible = true;
    },
    /* 分页 */
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    /* 分页 */
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    /* 配置修改 */
    updateData() {
      const { id, productId1, productId2, productId3, productId1SettleCount, productId2SettleCount, processMode } = this.formData;
      const params = { id, productId1, productId2, productId3, productId1SettleCount, productId2SettleCount, processMode };
      this.submitData("patch", this.$t("tips.update"), params);
    },
    /* 添加配置 */
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    submitData(method, msg, params) {
      this.submitDataBefore(method != "delete", (_) => {
        if (this.checkSameProduct(params || this.formData)) {
          this.$notify({
            title: this.$t("tips.error"),
            message: "产品不能相同",
            type: "error",
            duration: 2000,
          });
          return;
        }
        API.GetProductConfig(method, params || this.formData).then((res) => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + " " + this.$t("tips.success"),
            type: "success",
            duration: 2000,
          });
          this.queryData.page = 1;
          this.handleList();
          this.dialogFormVisible = false;
        });
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate((_) => _ && cb()) : cb();
    },
    /* 获取ALL产品列表 */
    getAllProductList() {
      API.GetProductList("ALL").then((res) => {
        this.productAllList = res.data.data;
      });
    },
    /* 检测是否提交相同产品 */
    checkSameProduct(val) {
      const { productId1, productId2, productId3 } = val;
      const array = [productId1, productId2, productId3];
      return new Set(array).size !== array.length;
    },
  },
};
</script>
