<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> <i class="el-icon-date"></i> {{ $t("navbar.operation") }} </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("navbar.productGlobalSplitterConfig") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-button class="filter-item" style="float: right; margin-right: 0px !important" @click="handleCreate" type="primary" icon="el-icon-edit">
          {{ $t("table.add") }}
        </el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" label="设备类型">
          <template slot-scope="{ row }">
            {{ row.deviceType == 1 ? "Android" : "iOS" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="首贷产品数量" prop="firstApplyProductCount" />
        <el-table-column align="center" label="结清次数" prop="totalSettleCount" />
        <el-table-column align="center" label="创建人" prop="createBy" />
        <el-table-column align="center" label="更新人" prop="updateBy" />
        <el-table-column align="center" :label="$t('table.operate')" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{ $t("table.edit") }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryData.rows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog :close-on-click-modal="false" :title="formData.id ? '编辑' : '添加'" :visible.sync="dialogFormVisible" width="680px">
        <el-form ref="dataForm" :model="formData" label-position="right" :rules="rules" label-width="150px">
          <el-form-item label="设备类型" prop="deviceType">
            <el-radio-group v-model="formData.deviceType">
              <el-radio :label="1">Android</el-radio>
              <el-radio :label="2">iOS</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="首贷产品数量" prop="firstApplyProductCount">
            <el-input v-model="formData.firstApplyProductCount" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></el-input>
          </el-form-item>
          <el-form-item label="结清次数" prop="totalSettleCount">
            <el-input v-model="formData.totalSettleCount" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="formData.id" type="primary" @click="updateData">{{ $t("table.confirm") }}</el-button>
          <el-button v-else type="primary" @click="createData">{{ $t("table.confirm") }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { productSplitterGlobalConfig } from "@/api/request";
import API from "@/api";
export default {
  name: "productGlobalSplitterConfig",
  data() {
    return {
      dialogFormVisible: false,
      listLoading: false,
      queryData: { page: 1, rows: 10 },
      formData: {},
      isCreate: false,
      list: [],
      total: null,
      rules: {
        deviceType: [{ required: true, message: "请选择设备类型" }],
        firstApplyProductCount: [{ required: true, message: "请输入首贷产品数量" }],
        totalSettleCount: [{ required: true, message: "请输入结清次数" }],
      },
    };
  },
  mounted() {
    this.handleList();
  },
  methods: {
    /* API分流全局配置配置列表 */
    handleList() {
      this.listLoading = true;
      productSplitterGlobalConfig("get", this.queryData)
        .then((res) => {
          this.list = res.data;
          this.total = res.data.total;
          this.listLoading = false;
        })
        .catch((_) => (this.listLoading = false));
    },

    /* 重置表单 */
    restFormData() {
      this.formData = {
        id: undefined,
        deviceType: undefined,
        firstApplyProductCount: undefined,
        totalSettleCount: undefined,
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    /* 添加操作 */
    handleCreate() {
      this.isCreate = true;
      this.restFormData();
      this.dialogFormVisible = true;
    },
    /* 编辑操作 */
    handleUpdate(row) {
      this.dialogFormVisible = true;
      this.formData = row;
    },
    /* 分页 */
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    /* 分页 */
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    /* 配置修改 */
    updateData() {
      const { id, totalSettleCount, firstApplyProductCount, deviceType } = this.formData;
      const params = { id, firstApplyProductCount, totalSettleCount, deviceType };
      this.submitData("patch", this.$t("tips.update"), params);
    },
    /* 添加配置 */
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    submitData(method, msg, params) {
      this.submitDataBefore(method != "delete", (_) => {
        API.productGlobalConfig(method, params || this.formData).then((res) => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + " " + this.$t("tips.success"),
            type: "success",
            duration: 2000,
          });
          this.queryData.page = 1;
          this.handleList();
          this.dialogFormVisible = false;
        });
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate((_) => _ && cb()) : cb();
    },
  },
};
</script>
