<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-date"></i> {{$t('navbar.development')}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('navbar.splitterStageRuleRelLimit')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="extra-btn">
                <el-button type="danger" :icon="cacheRefreshing?'el-icon-loading':'el-icon-refresh'" :disabled="cacheRefreshing" @click="refreshCache">刷新缓存</el-button>
                <el-tooltip class="item" effect="dark" content="修改或新增配置后需要刷新缓存才能生效！" placement="bottom-end">
                    <i class="el-icon-question"></i>
                </el-tooltip>
            </div>
            <div>
                <el-select class="filter-item" style="width: 160px" @change="fillQueryOptions" v-model="queryData.terminalId" placeholder="终端业务线">
                    <el-option v-for="item in terminalList" :key="item.id" :label="`${item.id}-${item.terminalName}`" :value="item.id">
                    </el-option>
                </el-select>
                <el-select clearable class="filter-item" style="width: 180px" @change="handleList(1)" v-model="queryData.productId" placeholder="产品" v-if="queryData.terminalId">
                    <el-option v-for="item in productList" :key="item.id" :label="`${item.id}-${item.productName}`" :value="item.id">
                    </el-option>
                </el-select>
                <el-select clearable class="filter-item" style="width: 160px" @change="handleList(1)" v-model="queryData.ruleId" placeholder="决策规则" v-if="queryData.terminalId">
                    <el-option v-for="item in ruleList" :key="item.id" :label="`${item.id}-${item.name}`" :value="item.id">
                    </el-option>
                </el-select>
                <el-button class="filter-item" v-if="queryData.terminalId" style="float: right; margin-right: 0px !important" @click="handleCreate" type="primary" icon="el-icon-edit">
                    {{$t('table.add')}}
                </el-button>
            </div>
            <span v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="刷新中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></span>

            <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>

                <el-table-column align="center" label="终端业务线">
                    <template slot-scope="scope">
                        <span>{{scope.row.terminalId | terminalFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="场景ID">
                    <template slot-scope="scope">
                        <span>{{scope.row.sceneId}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="产品" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.productId | productFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="产品决策阶段" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.stageId | stageFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="决策规则" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.ruleId | ruleFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="规则配置" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.ruleVal}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否启用">
                    <template slot-scope="scope">
                        <span :class="[`status-${scope.row.enabled}-cls`]">{{scope.row.enabled | enabledFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.updateTime}}</span>
                    </template>
                </el-table-column>

                <el-table-column align="center" :label="$t('table.operate')" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{$t('table.edit')}}</el-button>
                    </template>
                </el-table-column>

            </el-table>

            <div class="pagination-container">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]" :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

            <el-dialog :close-on-click-modal="false" :title="$t('table.edit')" :visible.sync="dialogFormVisible" :before-close="closeDialog">

                <el-form ref="dataForm" :model="formData" label-position="left" :rules="rules" label-width="120px" style='width: 500px; margin-left:50px;'>

                    <el-form-item label="终端业务线" prop="terminalId" v-if="isCreate">
                        <!-- <el-input readonly v-model="formData.terminalId"></el-input> -->
                        <div class="inp">{{formData.terminalId |terminalFmt }}</div>
                    </el-form-item>
                    <el-form-item label="产品" prop="productId" v-if="isCreate">
                        <el-select v-model="formData.productId" :placeholder="$t('table.choose')">
                            <el-option v-for="item in productList" :key="item.id" :label="`${item.id}-${item.productName}`" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="决策规则" prop="ruleId" v-if="isCreate">
                        <el-select v-model="formData.ruleId" :placeholder="$t('table.choose')">
                            <el-option v-for="item in ruleList" :key="item.id" :label="`${item.id}-${item.name}`" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规则配置" prop="ruleVal">
                        <el-input type="textarea" :rows="6" v-model="formData.ruleVal"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" :rows="6" v-model="formData.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input type="number" v-model="formData.sortNo"></el-input>
                    </el-form-item>
                    <el-form-item label="是否启用">
                        <el-switch v-model="formData.enabled" :active-value=1 :inactive-value=0></el-switch>
                    </el-form-item>

                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
                    <el-button v-if="isCreate" type="primary" @click="createData">{{$t('table.confirm')}}</el-button>
                    <el-button v-else type="primary" @click="updateData">{{$t('table.confirm')}}</el-button>
                </div>

            </el-dialog>

        </div>
    </div>
</template>

<script>
import { splitterStageRuleRelLimitApi, splitterStageRuleRelLimitOptions, refreshCache } from "@/api/request";
const terminalMap = new Map();
const productMap = new Map();
const ruleMap = new Map();
const stageMap = new Map();
export default {
    name: "SplitterStageRuleRelLimit",
    data() {
        return {
            dialogFormVisible: false,
            listLoading: false,
            queryData: { terminalId: undefined, productId: undefined, ruleId: undefined, page: 1, rows: 10 },
            formData: { terminalId: undefined, productId: undefined, ruleId: undefined, ruleVal: undefined, enabled: 1 },
            isCreate: false,
            list: [],
            total: 1,
            rules: {
                terminalId: [{ required: true, message: "请选择终端业务线" }],
                productId: [{ required: true, message: "请选择产品" }],
                ruleId: [{ required: true, message: "请选择决策规则" }],
                ruleVal: [{ required: true, message: "请输入规则配置" }],
            },
            terminalList: [],
            productList: [],
            ruleList: [],
            cacheRefreshing: false,
            fullscreenLoading: false
        };
    },
    mounted() {
        this.getOptions().then(res => {
            this.terminalList = res.data;
            this.terminalList.forEach(e => terminalMap.set(e.id, e));
        });
    },
    filters: {
        enabledFmt(v) {
            return v == 1 ? '启用' : (v == 0 ? '禁用' : '未知状态');
        },
        terminalFmt(v) {
            let d = terminalMap.get(v);
            return `${v}${(d && d.terminalName) ? ('-' + d.terminalName) : ''}`;
        },
        productFmt(v) {
            let d = productMap.get(v);
            return `${v}${(d && d.productName) ? ('-' + d.productName) : ''}`;
        },
        stageFmt(v) {
            let d = stageMap.get(v);
            return `${v}${(d && d.name) ? ('-' + d.name) : ''}`;
        },
        ruleFmt(v) {
            let d = ruleMap.get(v);
            return `${v}${(d && d.name) ? ('-' + d.name) : ''}`;
        }
    },
    methods: {
        handleList(isResetPage) {
            this.listLoading = true;
            if (isResetPage) {
                this.queryData.page = 1;
                this.queryData.rows = 10;
            }
            splitterStageRuleRelLimitApi("get", this.queryData)
                .then(res => {
                    this.list = res.data;
                    this.total = res.total;
                    this.listLoading = false;
                })
                .catch(_ => (this.listLoading = false));
        },
        handleCreate() {
            this.formData.terminalId = this.queryData.terminalId;
            this.isCreate = true;
            this.dialogFormVisible = true;
        },
        handleUpdate(row) {
            this.formData = Object.assign({}, row);
            this.dialogFormVisible = true;
        },
        closeDialog() {
            this.dialogFormVisible = false;
            this.isCreate = false;
            this.restFormData();
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleSizeChange(v) {
            this.queryData.rows = v;
            this.handleList();
        },
        handleCurrentChange(v) {
            this.queryData.page = v;
            this.handleList();
        },
        updateData() {
            this.submitData("patch", this.$t("tips.update"));
        },
        createData() {
            this.submitData("post", this.$t("tips.add"));
        },
        submitData(method, msg, param, i) {
            this.submitDataBefore(method != "delete", _ => {
                splitterStageRuleRelLimitApi(method, param || this.formData).then(res => {
                    this.$notify({
                        title: this.$t("tips.success"),
                        message: msg + ' ' + this.$t("tips.success"),
                        type: "success",
                        duration: 2000
                    });
                    this.$confirm('是否是否刷新缓存', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.refreshCache()
                    }).catch(() => {
                    });
                    this.handleList();
                    this.closeDialog();
                });
            });
        },
        submitDataBefore(isCheck, cb) {
            isCheck ? this.$refs.dataForm.validate(_ => _ && cb()) : cb();
        },
        restFormData() {
            this.formData = { terminalId: undefined, productId: undefined, ruleId: undefined, ruleVal: undefined, enabled: 1 };
        },
        async getOptions(terminalId) {
            return await splitterStageRuleRelLimitOptions(terminalId);
        },
        fillQueryOptions() {
            this.queryData.productId = undefined;
            this.queryData.ruleId = undefined;
            this.getOptions(this.queryData.terminalId).then(res => {
                this.productList = res.data.product;
                this.ruleList = res.data.rule;
                this.fillNameFilters(res.data);
                this.handleList(1);
            });
        },
        fillNameFilters(data) {
            productMap.clear();
            data.product && data.product.forEach(e => productMap.set(e.id, e));
            ruleMap.clear();
            data.rule && data.rule.forEach(e => ruleMap.set(e.id, e));
            stageMap.clear();
            data.stage && data.stage.forEach(e => stageMap.set(e.id, e));
        },
        refreshCache() {
            this.cacheRefreshing = true;
            this.fullscreenLoading = true;
            refreshCache().then(res => {
                this.cacheRefreshing = false;
                this.fullscreenLoading = false;
                this.showRefreshCacheResult(res.data);
            }).catch(_ => { this.cacheRefreshing = false; this.fullscreenLoading = false; });
        },
        showRefreshCacheResult(data) {
            if (data) {
                let type = (data.successNum == data.allNum) ? 'success' : 'error';
                let dataHtml = `<div>刷新成功节点数：${data.successNum}</div>`;
                dataHtml += `<div>刷新失败节点数：${data.failureNum}</div>`;
                dataHtml += `<div>总节点数：${data.allNum}</div>`;
                if (data.failureUrls && data.failureUrls.length > 0) {
                    let failureUrlsDiv = '失败节点列表：<br>'
                    data.failureUrls.forEach(e => {
                        failureUrlsDiv += `<div>${e}</div>`;
                    })
                    failureUrlsDiv = `<div>${failureUrlsDiv}</div>`;
                    dataHtml += failureUrlsDiv;
                }
                dataHtml = `<div>${dataHtml}</div>`;
                this.$message({
                    dangerouslyUseHTMLString: true,
                    duration: 0,
                    showClose: true,
                    offset: 300,
                    type: type,
                    customClass: 'custom-message',
                    message: dataHtml
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '刷新失败'
                });
            }
        }
    }
};
</script>
<style lang="less" scoped>
.status-1-cls {
    color: #67c23a;
    font-weight: bold;
}
.status-0-cls {
    color: #f56c6c;
    font-weight: bold;
}
.extra-btn {
    float: right;
    margin-top: -72px;
    display: flex;
    margin-right: -30px;
    i {
        margin: auto 5px;
        font-size: larger;
        color: #909399;
    }
}
.inp {
    height: 32px;
    line-height: 32px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding-left: 15px;
}
</style>
<style>
.custom-message {
    box-shadow: 0px 0px 10px #888888;
}
</style>
