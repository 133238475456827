<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i
          >{{ $t("navbar.operation") }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          $t("navbar.sendConfiguration")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="flex-justify-between boxmargin">
        <div>发送配置</div>
        <el-button
          class="filter-item"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-edit"
          @click="Adddata()"
          >{{ $t("table.add") }}</el-button
        >
      </div>

      <div class="tabletemplate el-switcfield el-radiofield radiofieldback">
        <el-table
          :data="list"
          v-loading="listLoading"
          :element-loading-text="$t('table.loading')"
          border
          highlight-current-row
        >
          <el-table-column
            :label="this.$t('table.iName')"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.taskName  }}</p>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('table.startTime')"
            align="center"
            min-width="250"
          >
            <template slot-scope="scope">
              <el-select
                :disabled="scope.row.updateEditstatus == false"
                v-model="scope.row.cronHour"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="item in cronHourarr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              ：
              <el-select
                :disabled="scope.row.updateEditstatus == false"
                v-model="scope.row.cronMinute"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="item in cronMinutearr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column
            :label="this.$t('table.status')"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.status == 1 ? "启用" : "停用" }}</p>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            :label="$t('table.operate')"
            min-width="300"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.status" disabled
              v-if="scope.row.addEditstatus">
                <el-radio-button
                  label="1"
                  @click.native="EditONMethod(scope.row)"
                  >ON</el-radio-button
                >
                <el-radio-button
                  label="0"
                  @click.native="EditOFFMethod(scope.row)"
                  >OFF</el-radio-button
                >
              </el-radio-group>

              <el-button
                :disabled="scope.row.status == 1"
                type="primary"
                size="mini"
                @click="servaMethod(scope.row)"
                >{{
                  scope.row.updateEditstatus == false
                    ? "修改"
                    : $t("table.preservation")
                }}</el-button
              >

              <el-button
                v-if="scope.row.addEditstatus"
                type="danger"
                size="mini"
                @click="deleteMethod(scope.row)"
                >{{ $t("table.delete") }}</el-button
              >
              <!-- <el-button
                v-if="!scope.row.addEditstatus"
                type="danger"
                size="mini"
                @click="falsedelete(scope.row)"
                >{{ $t("table.delete") }}</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="upperLimitbox">
        发送上限：<span>{{ NumberArticles }} </span>条
      </div>

      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryData.row"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="$t('table.edit')"
      :visible.sync="dialogFormVisible"
      :before-close="closeDialog"
    >
      11
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api";
import compTable from "@/components/table/index";
import { PatchStrategyConfig } from "@/api/request";

import { cronHourarr, cronMinutearr } from "./index";

export default {
  components: {
    compTable,
  },
  data() {
    return {
      cronHourarr,
      cronMinutearr,
      
      dialogFormVisible: false,
      listLoading: false,
      queryData: { page: 1, rows: 10 }, //分页
      total: 1, //表格总条数
      list: [], //表格数据
      eventList: [], //事件列表
      NumberArticles: 1,
    };
  },
  created() {
    this.messageSendTask(); //表格列表请求
  },
  mounted() {},
  methods: {
    //保存修改
    servaMethod(row) {
      console.log(row);
      if (row.addEditstatus == false) {
        let list = Object.assign({}, row);
        delete list.addEditstatus;
        console.log(list);
        this.POSTmessageSendTask(list)
        return
      }
      if (row.updateEditstatus == false) {
        row.updateEditstatus = true;
        this.list = JSON.parse(JSON.stringify(this.list));
        console.log(this.list);
      } else {
        let form = Object.assign({}, row);
        this.PATCHmessageSendTask(form);
      }
    },

    //ON 方法
    EditONMethod(row) {
      this.$confirm("您确定要启用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let form = Object.assign({}, row);
          form.status = 1;
          this.PATCHmessageSendTask(form);
        })
        .catch(() => {});
    },
    //off方法
    EditOFFMethod(row) {
      this.$confirm("您确定要停用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let form = Object.assign({}, row);
          form.status = 0;
          this.PATCHmessageSendTask(form);
        })
        .catch(() => {});
    },
    //删除方法
    deleteMethod(row) {
      this.$confirm("您确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.id);
          this.DELETEmessageSendTask({ id: row.id });
        })
        .catch(() => {});
    },
    //新增
    Adddata() {
      // if (this.list.length >= 3) {
      //   console.log(this.list.length);
      //   this.$notify.error({
      //     title: this.$t("tips.error"),
      //     message: "发送配置新增上限 3 条！",
      //     duration: 2000,
      //   });
      //   return;
      // } else {
      console.log(this.list);
      this.list.push({
        cronHour: 0,
        cronMinute: 0,
        status: 0,
        taskName: "配置名称" + (Number(this.list.length) + 1),
        addEditstatus: false, 
      });
      // let form ={}
      // form.cronHour = 0;
      // form.cronMinute = this.list.length;
      // console.log(form);
      // this.POSTmessageSendTask(form)
      // }
    },

    closeDialog() {
      this.dialogFormVisible = false;
    },

    //请求
    async messageSendTask() {
      const { data: data, code: code } = await API.messageSendTask(
        this.queryData
      );
      if (data.data != null) {
        this.list = data.data;
        this.list.forEach((item, index) => {
          console.log(item);
          item.updateEditstatus = false;
          item.addEditstatus = true
        });
        console.log(this.list);
        this.total = data.total;
        this.NumberArticles = data.total;
      } else {
      }
    },
    async PATCHmessageSendTask(list) {
      const { data: data, code: code } = await API.PATCHmessageSendTask(list);
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + " " + this.$t("tips.success"),
          type: "success",
          duration: 2000,
        });
      } else {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + " " + this.$t("tips.error"),
          duration: 2000,
        });
      }
      this.messageSendTask();
    },

    async DELETEmessageSendTask(list) {
      const { data: data, code: code } = await API.DELETEmessageSendTask(list);
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + " " + this.$t("tips.success"),
          type: "success",
          duration: 2000,
        });
      } else {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + " " + this.$t("tips.error"),
          duration: 2000,
        });
      }
      this.messageSendTask();
    },
    async POSTmessageSendTask(list) {
      const { data: data, code: code } = await API.POSTmessageSendTask(list);
      if (data.code == 200) {
        this.$notify({
          title: this.$t("tips.success"),
          message: data.msg + " " + this.$t("tips.success"),
          type: "success",
          duration: 2000,
        });
      } else {
        this.$notify.error({
          title: this.$t("tips.error"),
          message: data.msg + " " + this.$t("tips.error"),
          duration: 2000,
        });
      }
      this.messageSendTask();
    },

    //分页
    handleSizeChange(v) {
      this.queryData.row = v;
      this.messageSendTask();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.messageSendTask();
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.boxmargin {
  margin: 10px 0;
}
.upperLimitbox {
  margin: 10px 0;

  span {
    color: #dd2940;
  }
}
.el-switch.is-disabled {
  opacity: 1;
}
.el-switcfield /deep/ .el-switch.is-disabled .el-switch__core,
.el-switch.is-disabled .el-switch__label {
  cursor: pointer !important;
}
.radiofieldback
  /deep/
  .el-radio-button__orig-radio:disabled:checked
  + .el-radio-button__inner {
  background-color: #409eff;
  color: #fff;
}
.el-radiofield
  /deep/
  .el-radio-button__orig-radio:disabled
  + .el-radio-button__inner {
  cursor: pointer !important;
  color: #65676b;
}
</style>