<template>
  <div class="tabletemplate el-switcfield el-radiofield radiofieldback">
    <el-table
      :data="tablelistarr"
      v-loading="listLoading"
      :element-loading-text="$t('table.loading')"
      border
      highlight-current-row
    >
      <el-table-column
        v-if="indexdisplay"
        type="index"
        label="序号"
        width="60"
        align="center">
      </el-table-column>

      <el-table-column
        v-for="(list, index) in tableHeader"
        :key="index"
        :label="list.label"
        :prop="list.prop"
        :formatter="list.formatter"
        :min-width="list.minwidth"
        align="center"
      >
        <!-- <template slot-scope="scope" v-if="list.startTime">
          <span>{{ scope.row.startTime }}</span>
        </template> -->
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('table.operate')"
        min-width="280"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">

          <el-radio-group v-if="onoffDisplay" v-model="scope.row.status" disabled>
            <el-radio-button label="1" @click.native="EditONMethod(scope.row)">ON</el-radio-button>
            <el-radio-button label="0" @click.native="EditOFFMethod(scope.row)">OFF</el-radio-button>
          </el-radio-group>

          <el-button
            v-if="editdisplay"
            type="primary"
            size="mini"
            @click="EditMethod(scope.row)"
            >{{ $t("table.edit") }}
          </el-button>
          
          <el-button
            v-if="configDisplay"
            type="primary"
            size="mini"
            @click="ConfigDisplay(scope.row)"
            >{{ $t("navbar.strategyConfig") }}</el-button
          >

          <el-button
            v-if="deleteDisplay"
            type="danger"
            size="mini"
            @click="deleteMethod(scope.row)"
            >{{ $t("table.delete") }}</el-button
          >

          <el-switch
            v-if="statusDisplay"
            v-model="scope.row.status"
            disabled
            @click.native="EditStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    //是否显示编辑
    editdisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否显示删除
    deleteDisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否显示switch开关
    statusDisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否显示配置
    configDisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否显示单选按钮
    onoffDisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否显示序号
    indexdisplay: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    tablelistarr: {
      type: Array,
      default: () => [],
    },
    // 表头数据
    tableHeader: {
      type: Array,
      default: function () {
        return [];
      },
    },
    listLoading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    formatter: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      radio1: "1",
    };
  },
  created() {},
  mounted() {},
  methods: {
    EditMethod(row) {
      this.$emit("EditMethod", row);
    },
    EditStatus(row) {
      this.$emit("EditStatus", row);
    },
    EditONMethod(row) {
      this.$emit("EditONMethod", row);
    },
    EditOFFMethod(row) {
      this.$emit("EditOFFMethod", row);
    },
    ConfigDisplay(row) {
      this.$emit("ConfigDisplay", row);
    },
    deleteMethod(row) {
      this.$emit("deleteMethod", row);
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.el-switch.is-disabled {
  opacity: 1;
}
.el-switcfield /deep/ .el-switch.is-disabled .el-switch__core,
.el-switch.is-disabled .el-switch__label {
  cursor: pointer !important;
}
.radiofieldback /deep/ .el-radio-button__orig-radio:disabled:checked+.el-radio-button__inner {
    background-color: #409EFF;
    color: #fff;
}
.el-radiofield /deep/ .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
  cursor: pointer !important;
  color: #65676B;
  
}
</style>


